<!--
 * @Author: your name
 * @Date: 2021-07-31 14:57:40
 * @LastEditTime: 2021-09-27 10:53:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\components\comment.vue
-->
<template>
  <div class="comment">
    <div class="title flex-h flex-vc">
      <img src="../../../assets/img/msg.png" />
      <span>留言咨询（{{ totalCount }}）</span>
    </div>
    <ul>
      <el-scrollbar style="height: 100%">
        <li v-for="(item, index) in list" :key="index">
          <div class="header flex-h flex-vc">
            <div class="header-left">
              <img :src="item.headUrl" />
              <span>{{ item.userName }}</span>
            </div>
            <span>{{ $formatDate(item.messageTime, "{y}-{m}-{d}") }}</span>
          </div>
          <div class="content">
            {{ item.context }}
          </div>
        </li>
      </el-scrollbar>
    </ul>

    <div v-if="show">
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 4 }"
        placeholder="请输入内容"
        maxlength="50"
        show-word-limit
        clearable
        v-model="context"
      >
      </el-input>
      <el-checkbox v-model="checked" style="margin: 10px 0"
        >我要匿名</el-checkbox
      >
      <div class="btn" @click="sub">提交</div>
    </div>
    <div class="btn" v-else @click="show = true">我要留言</div>
  </div>
</template>
<script>
import { leaveMessageList, saveLeaveMessage } from "@/api/index";
export default {
  data() {
    return {
      totalCount: 0,
      context: "",
      checked: false,
      show: false,
      list:[]
    };
  },
  methods: {
    sub() {
      if (!this.context) {
        this.$message({
          message: "请输入信息",
          type: "warning",
        });
        return false;
      }
      saveLeaveMessage({
        context: this.context,
        queueId: this.$route.query.id,
        type: this.checked ? 0 : 1,
      }).then((res) => {
        this.show = false;
        this.context="";
        this.checked=false;
        this.getList();
      });
    },
    getList() {
      leaveMessageList({
        currPage: 1,
        pageLimit: 100,
        queueId: this.$route.query.id,
      }).then((res) => {
        this.list = res.data.list;
        this.totalCount = res.data.totalCount;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none
}
.comment {
  width: 294px;
  background: #ffffff;
  border-radius: 8px;
  padding: 11px 22px;
  box-sizing: border-box;

  .title {
    margin-bottom: 30px;
    img {
      width: 30px;
    }
    span {
      margin-left: 6px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6e6e;
    }
  }
  ul {
    height: 500px;
    margin-bottom: 20px;
    li {
      border-bottom: 1px solid #c0c0c0;
      margin-bottom: 14px;
      &:last-child {
        border-bottom: none;
      }
      .header {
        justify-content: space-between;
        .header-left {
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
          }
          span {
            vertical-align: middle;

            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4f4f4f;
          }
        }
      }
      .content {
        margin: 14px 0 16px 0;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 17px;
      }
    }
  }
  .btn {
    width: 98px;
    height: 31px;
    border-radius: 16px;
    border: 1px solid #ff6e6e;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff6e6e;
    line-height: 31px;
    margin: 0 auto;
  }
}
</style>