<!--
 * @Author: your name
 * @Date: 2021-07-31 13:21:44
 * @LastEditTime: 2021-08-12 13:49:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\components\tab1.vue
-->
<template>
  <div class="content">
   {{organizationRemark}}
  </div>
</template>
<script>
export default {
  props:['organizationRemark']
}
</script>
<style lang="scss" scoped>
.content{
    
font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #808080;
line-height: 25px;
}
</style>