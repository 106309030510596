<!--
 * @Author: your name
 * @Date: 2021-07-31 13:21:44
 * @LastEditTime: 2021-08-14 14:26:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\components\tab1.vue
-->
<template>
  <div>
    <el-table :data="activityList" style="width: 100%">
        <el-table-column prop="activityName" label="活动名称">
            <template slot-scope="scope">
                <el-button size="mini"
                           type="text"
                           @click="
              $router.push({
                name: 'activityDetail',
                query: { id: scope.row.activityId },
              })
            ">{{ scope.row.activityName }}</el-button>
            </template>
        </el-table-column>
      <el-table-column align="center" prop="date" label="活动日期" width="500">
        <template slot-scope="scope">
          {{ $formatDate(scope.row.startTime) }} -
          {{ $formatDate(scope.row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="活动状态" width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">即将开始</span>
          <span v-if="scope.row.status == 1">正在进行</span>
          <span v-if="scope.row.status == 2">已结束</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template> 
<script>
export default {
  props: {
    activityList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>