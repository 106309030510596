<!--
 * @Author: your name
 * @Date: 2021-07-30 15:38:22
 * @LastEditTime: 2021-08-17 16:14:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\detail.vue
-->
<template>
  <div class="detail">
    <div class="serveDetail container flex-h">
      <div class="main">
        <div class="main-header flex-h">
          <div class="main-left flex-v flex-vc">
            <img class="head" :src="logoUrl" />
            <div class="attention flex-h flex-vc flex-hc" @click="changeFocus">
              <img src="@/assets/img/xin2.png" v-if="attention == 0" />
              <img src="@/assets/img/xin1.png" v-if="attention == 1" />
              <span>关注</span>
            </div>
          </div>
          <div class="content">
            <h1>{{ queueName }}</h1>
            <p>发起单位：{{ companyName }}</p>
            <p>
              主要服务方向：
              <span v-for="(item, index) in serviceOrientation" :key="index">{{
                item
              }}</span>
            </p>
            <p>服务队编号：{{ queueCode }}</p>
            <div class="evaluate">
              <span>评价：</span>
              <img
                src="@/assets/img/start.png"
                v-for="(item, index) in score"
                :key="index"
              />
            </div>
            <div class="tags flex-h">
              <div class="tag">
                <img src="@/assets/img/team1.png" />
                <p>{{ queueNum == null ? 0 : queueNum }}人</p>
                <p>队员人数</p>
              </div>
              <div class="tag">
                <img src="@/assets/img/team2.png" />
                <p>{{ activityNum == null ? 0 : activityNum }}次</p>
                <p>发起志愿活动</p>
              </div>
              <div class="tag">
                <img src="@/assets/img/team3.png" />
                <p>{{ serverTime == null ? 0 : serverTime }}小时</p>
                <p>服务时长</p>
              </div>
            </div>
          </div>
        </div>
        <div class="desc">
          {{ manifesto }}
        </div>
        <div class="tabs flex-h">
          <div
            class="tab"
            :class="{ active: component == 'Tab1' }"
            @click="component = 'Tab1'"
          >
            组织介绍
          </div>
          <div
            class="tab"
            :class="{ active: component == 'Tab2' }"
            @click="component = 'Tab2'"
          >
            发起的活动
          </div>
          <div
            class="tab"
            :class="{ active: component == 'Tab3' }"
            @click="component = 'Tab3'"
          >
            队员信息
          </div>
        </div>
        <div
          class="box"
          :is="component"
          :organizationRemark="organizationRemark"
          :memberList="memberList"
          :activityList="activityList"
        ></div>
        <div class="save" v-if="auditStatus != 5">
          <div
            class="fill"
            v-if="auditStatus == 1 || auditStatus == 0"
            @click="noSign"
          >
            取消加入
          </div>
          <div
            class="fill"
            v-if="auditStatus == 4 || auditStatus == 2 || auditStatus == 3"
            @click="goSign"
          >
            我要加入
          </div>
          <p v-if="auditStatus == 0">审核中</p>
          <p v-if="auditStatus == 1">已通过</p>
        </div>
      </div>
      <aside>
        <div class="contact">
          <div class="title flex-h flex-vc">
            <img src="@/assets/img/phone.png" />
            <span>联系方式</span>
          </div>
          <p>负责人：{{ principal }}</p>
          <p>联系电话：{{ contactNumber }}</p>
          <p>联系邮箱：{{ contactEmail }}</p>
          <p>联系地址：{{ contactAddr }}</p>
        </div>
        <comment />
      </aside>
    </div>
  </div>
</template>
<script>
import Tab1 from "./components/tab1.vue";
import Tab2 from "./components/tab2.vue";
import Tab3 from "./components/tab3.vue";
import comment from "./components/comment.vue";
import { queueDetail, queueContacts, attention, passOut } from "@/api/index";
export default {
  data() {
    return {
      applyId: "",
      logoUrl: "",
      auditStatus: null,
      queueName: "",
      companyName: "",
      queueCode: "",
      manifesto: "",
      organizationRemark: "",
      memberList: "",
      activityList: [],
      principal: "",
      contactNumber: "",
      contactEmail: "",
      contactAddr: "",
      serviceOrientation: [],
      score: 0,
      queueNum: 0,
      activityNum: 0,
      serverTime: 0,
      attention: 1,

      component: "Tab1",
    };
  },
  components: { Tab1, Tab2, Tab3, comment },
  methods: {
    changeFocus() {
      let status = this.attention == 1 ? 0 : 1;
      attention({
        attentionId: this.$route.query.id,
        status: status,
        type: 1,
      }).then((res) => {
        if (status == 1) {
          this.$message({
            type: "success",
            message: "取消关注成功!",
          });
        }
        if (status == 0) {
          this.$message({
            type: "success",
            message: "关注成功!",
          });
        }
        this.getData();
      });
    },
    goSign() {
      this.$confirm("确定加入该服务队吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        passOut({
          auditStatus: 0,
          queueId: this.$route.query.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "报名成功!",
          });
          this.sign = true;
          this.getData();
        });
      });
    },
    noSign() {
      this.$confirm("确定退出该服务队吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        passOut({
          auditStatus: 1,
          queueId: this.$route.query.id,
          applyId: this.applyId,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "取消成功!",
          });
          this.sign = false;
          this.getData();
        });
      });
    },
    getData() {
      queueDetail({
        id: this.$route.query.id,
      }).then((res) => {
        this.applyId = res.data.applyId;
        this.logoUrl = res.data.logoUrl;
        this.queueName = res.data.queueName;
        this.companyName = res.data.companyName;
        this.queueCode = res.data.queueCode;
        this.manifesto = res.data.manifesto;
        this.score = res.data.score;
        this.queueNum = res.data.queueNum;
        this.activityNum = res.data.activityNum;
        this.serverTime = res.data.serverTime;
        this.attention = res.data.attention;
        this.auditStatus = res.data.auditStatus;

        this.serviceOrientation = res.data.serviceOrientation;
        this.organizationRemark = res.data.organizationRemark;
        this.memberList = res.data.memberList;
        this.activityList = res.data.activityList;
      });
    },
    queueContacts() {
      queueContacts({
        id: this.$route.query.id,
        type: 1,
      }).then((res) => {
        this.principal = res.data.principal;
        this.contactNumber = res.data.contactNumber;
        this.contactEmail = res.data.contactEmail;
        this.contactAddr = res.data.contactAddr;
      });
    },
  },
  created() {
    this.getData();
    this.queueContacts();
  },
};
</script>
<style lang="scss" scoped>
.detail {
  background: #f6f6f6;
  padding: 25px 0;
  .serveDetail {
    justify-content: space-between;
  }
  .main {
    width: 889px;
    background: #ffffff;
    border-radius: 8px;
    padding: 36px;
    box-sizing: border-box;
    position: relative;
    .main-header {
      .main-left {
        .head {
          width: 140px;
          height: 140px;
          border-radius: 50%;
        }
        .attention {
          width: 109px;
          height: 37px;
          background: #f7f7f7;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 22px;
          img {
            width: 25px;
          }
          span {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #4f4f4f;
            margin-left: 5px;
          }
        }
      }
      .content {
        width: 535px;
        margin-left: 30px;
        h1 {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 6px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #737373;
          span {
            display: inline-block;

            height: 28px;
            background: #dbd7d7;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #797979;
            line-height: 28px;
            margin-right: 7px;
            padding: 0 16px;
            margin-bottom: 5px;
          }
        }
        .evaluate {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 900;
          color: #000000;
          margin-bottom: 30px;
          span {
            vertical-align: middle;
          }
          img {
            width: 20px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        .tags {
          justify-content: space-between;
          .tag {
            img {
              width: 60px;
              display: block;
              margin: 0 auto;
            }
            p {
              text-align: center;

              &:nth-of-type(1) {
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #242424;
                margin-top: 5px;
                margin-bottom: 2px;
              }
              &:nth-of-type(2) {
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #242424;
              }
            }
          }
        }
      }
    }
    .desc {
      margin-top: 20px;
      background: #fff0f0;
      border-radius: 8px;
      border: 1px solid #ff6e6e;
      font-size: 15px;
      font-family: MicrosoftYaHei;
      color: #898989;
      line-height: 20px;
      padding: 25px;
    }
    .tabs {
      margin-top: 27px;
      .tab {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 55px;
        cursor: pointer;
        padding-bottom: 2px;
        &.active {
          color: #ff6e6e;
          border-bottom: 5px solid #ff6e6e;
        }
      }
    }
    .box {
      padding-top: 25px;
    }
    .save {
      position: absolute;
      right: 0;
      top: 17px;
      .fill {
        width: 144px;
        height: 41px;
        background: #ff6e6e;
        border-radius: 100px 0px 0px 100px;
        cursor: pointer;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 41px;
      }
      p {
        text-align: center;
        margin-top: 12px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6e6e;
      }
    }
  }

  aside {
    float: right;
    .title {
      img {
        width: 30px;
      }
      span {
        font-size: 23px;
        font-family: MicrosoftYaHei;
        color: #ff6e6e;
        margin-left: 11px;
      }
    }

    .contact {
      width: 294px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 14px;
      margin-bottom: 13px;
      p {
        margin-top: 15px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 25px;
      }
    }
  }
}
</style>