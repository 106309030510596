<!--
 * @Author: your name
 * @Date: 2021-07-31 13:21:44
 * @LastEditTime: 2021-08-12 13:52:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\components\tab1.vue
-->
<template>
  <div class="userList flex-h">
    <div
      class="userItem flex-h flex-vc"
      v-for="(item, index) in memberList"
      :key="index"
    >
      <p class="name">{{item.operatorName}}</p>
      <p>{{item.operatorAccount}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    memberList:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.userList {
  flex-wrap: wrap;
  .userItem {
    margin-right: 200px;
    margin-bottom: 13px;
    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4f4f4f;
    }
    .name {
      width: 65px;
      margin: 0 9px;
      text-align: center;
    }
  }
}
</style>